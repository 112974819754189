#main_nav_box {
  border: 1px solid #8e8e8e;
  /* border: 5px solid red; */
  display: flex;
  justify-content: space-around;
  height: 100px;
}
#logo_name_box {
  display: flex;
  align-items: center;
  gap: 10px;
}
#options_box {
  align-items: center;
}
#carticon_loginbtn_box {
  justify-content: center;
  align-items: center;
  gap: 40px;
}

#options_box .btn:hover {
  color: #52771a;
  border-radius: 14px;
  background-color: #ecf3e0;
}
#options_box .btn {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

#logo_name_box #name {
  color: #52771a;
  font-family: Krona One;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 400;
}
