/* FormStyles.css */
#form_box {
  width: 604px;
  height: 636px;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #ccc;
}

#form_heading {
  color: #404040;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 20px;
}

.form-group svg {
  margin-right: 10px;
}

.form-label {
  color: #404040;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: #a0a0e0;
}

#btn {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#btn:hover {
  background-color: #0056b3;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group svg {
  margin-right: 10px;
  flex-shrink: 0;
}
.form-division-group {
  display: "flex";
}
