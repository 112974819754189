
#service_heading {
  color: #404040;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#service_details {
  color: #404040;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
 
}
#btn {
  border-radius: 14px;
  background: #52771a;
}

#vdo_box {
  border-radius: 14px;
  width: 426px;
  height: 276px;
  }



@media only screen and (max-width: 600px) {
  /* Styles for small screens go here */
  #vdo_box{
    width: auto;
    height: 200px;
  }
  
}