#btn {
  border-radius: 14px;
  background: #52771a;
  width: 320px;
  height: 40px;
  margin-top: 30px;
  color: #f3ffe1;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#normal_text {
  color: #404040;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4;
}
#login_btn {
  border-radius: 14px;
  border: 1px solid #52771a;
  background: #fff;
  color: #52771a;
  font-family: Inter;

  font-style: normal;
  font-weight: 500;
}
#login_btn:hover {
  color: #52771a;
  background-color: #f3ffe1;
}
 #newuser {
    color: #0336ff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    
} 
 #newusers {
    
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-top: 20px;
    
} 