#main_box_footer {
  border-radius: 24px 24px 0px 0px;
  background-color: #52771a;
  padding-bottom: 2%;
}
.heading_name {
  color: #fff;
  font-family: Inter;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.normal_text {
  color: #f3ffe1;

  font-style: normal;
  font-weight: 700;
  font-family: Inter, sans-serif;
  line-height: 2.5;
}
/* Footer container */

#lorem_text {
  color: #f3ffe1;

  font-style: normal;
  font-weight: 700;
  font-family: Inter, sans-serif;
}

/* Footer content boxes */
#first_box,
#second_box,
#third_box,
#fourth_box {
  /* border: 1px solid black; */
  margin:"auto",
  
}

/* Icon and text container */
.icon_or_text {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Image styles */
.icon_or_text img {
  width: 20px;
  height: 20px;
}
